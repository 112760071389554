import { CFlex, CMargin, DText, Flex } from '../../../../../styles/CommonStyles'
import Text from '../../../../../core/utils/Text'
import { LineBreak } from '../../../../../styles/main/MainCommonStyles'
import { AnimatePresence, motion } from 'framer-motion'
import { useState } from 'react'
import { CollapseLtr, CollapseRtl, SettingCardWrapper } from '../../../../../styles/main/setting'
import { useMainContext } from '../../../../../core/contexts/main'
import { useRef } from 'react'
import CountUp from 'react-countup'
import { useIsVisible } from 'react-is-visible'

const StakingPool = ({ pool, index }) => {
	const { lang } = useMainContext()
	const [showDetails, setShowDetails] = useState(false)
	const nodeRef = useRef()
	const isVisible = useIsVisible(nodeRef)

	const renderPeriods = () => {
		return (
			<>
				{pool?.periods?.map((period, index) => (
					<div className='mt-2' key={index}>
						{/* <DText>
					<Text tid='days' />: {period.Days} <Text tid='apr' />: {period.APR}%
				</DText> */}
						<div
							class='rounded-lg'
							style={{
								backgroundColor: '#e5e7eb',
								width: '25rem',
								borderRadius: '1rem',
								color: '#fff',
								marginTop: '1rem',
							}}
						>
							<div
								ref={nodeRef}
								class='rounded-lg text-gray-100 text-center p-1 h-full flex items-center text-center justify-center transition-all delay-1400 ease-in-out'
								style={{
									width: `${period.Days}%`,
									backgroundColor: '#0f935a',
									borderRadius: '1rem',
									paddingTop: '0.5rem',
									paddingBottom: '0.5rem',
									color: '#fff',
								}}
							>
								<div style={{ textAlign: 'center' }}>
									<div>
										<CountUp className='text-3xl' end={period.Days} /> <Text tid='days' />
									</div>
									<div>
										<CountUp className='text-xl' end={period.APR} /> <Text tid='apr' />
									</div>
								</div>
							</div>
						</div>
					</div>
				))}
			</>
		)
	}

	return (
		<SettingCardWrapper minHeight={'0'} main>
			<Flex fw wrap justify={'space-between'}>
				<DText main>
					 {`${index+1})`}
				</DText>
				<DText main>
					<Text tid={'coin'} />: {pool.coin}
				</DText>
				<DText main>
					<Text tid={'capacity'} />: {pool.maxStake}
				</DText>
				<DText main>
					<Text tid={'min-stack'} />: {pool.minStake}
				</DText>
				<DText main>
					<Text tid={'lockingPeriod'} />: {pool.lockingPeriod} <Text tid={'days'} />
				</DText>
			</Flex>

			<LineBreak style={{ margin: '12px auto 0 auto' }} />

			<AnimatePresence initial={false}>
				{showDetails && (
					<motion.div
						style={{ width: '100%', overflow: 'hidden' }}
						variants={collapseVariants}
						initial='collapsed'
						animate='open'
						exit='collapsed'
						transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
					>
						<CFlex fw align={'flex-start'}>
							<DText primary>
								<Text tid={'periods'} />
							</DText>
							<DText main>{renderPeriods()}</DText>
						</CFlex>
					</motion.div>
				)}
			</AnimatePresence>

			{lang === 'en' ? (
				<CollapseLtr
					size={24}
					open={showDetails}
					onClick={() => setShowDetails((state) => !state)}
				/>
			) : (
				<CollapseRtl
					size={24}
					open={showDetails}
					onClick={() => setShowDetails((state) => !state)}
				/>
			)}
		</SettingCardWrapper>
	)
}

const collapseVariants = {
	open: { opacity: 1, height: 'auto' },
	collapsed: { opacity: 0, height: 0 },
}

export default StakingPool
