import { useNavigate } from "react-router-dom"
import ListLayout from "../../components/layout/main/ListLayout"
import useSidebar from "../../core/hooks/layout/useSidebar"
import Text from "../../core/utils/Text"
import { fadeVariants } from "../../core/utils/theme"
import { CFlex, DText, Flex } from "../../styles/CommonStyles"
import { SubpageCard } from "../../styles/main/MainCommonStyles"

const Settings = () => {

    const navigate = useNavigate()
    const { routes } = useSidebar()
    const subPages = routes?.find(route => route.title === 'setting')?.items

    const onSubrouteClicked = (route) => {
        navigate(route.path)
    }

    return (
        <ListLayout>
            <Flex fw wrap>
                {subPages?.map(item => (
                    item.show &&
                        <SubpageCard
                            initial='out'
                            exit='out'
                            animate='in'
                            variants={fadeVariants}
                            onClick={() => onSubrouteClicked(item)}
                            key={item.title}
                        >
                            <CFlex fw fh justify='space-between'>
                                <img
                                    src={require(`../../assets/illustrations/setting/${item.title}.svg`)}
                                    alt=' '
                                    width={'180px'}
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.alt = '';
                                        e.target.src = null;
                                    }}
                                />
                                <DText primary cFontSize='0.9rem'>
                                    <Text tid={item.title} />
                                </DText>
                            </CFlex>
                        </SubpageCard>
                ))}
            </Flex>
        </ListLayout>
    )
}


export default Settings
