import { AnimatePresence } from 'framer-motion'
import ListLayout from '../../../components/layout/main/ListLayout'
import SettingCard from '../../../components/layout/main/SettingCard'
import Text from '../../../core/utils/Text'
import { CFlex, DText, Flex, IconWrapper } from '../../../styles/CommonStyles'
import { EditIcon, ModeDecoration } from '../../../styles/main/setting'
import SubmitSettingModal from '../../../components/modals/SubmitSettingModal'
import useStakingPools from '../../../core/hooks/main/setting/staking-pools/useStakingPools'
import { useGetStakingPools } from '../../../core/services/react-query/setting/staking-pools'
import StakingPoolUpdate from '../../../components/main/setting/staking-pools/updates/StakingPoolUpdate'
import StakingPool from '../../../components/main/setting/staking-pools/details/StakingPool'
import { useEffect } from 'react'
import SettingDetailsLayout from '../../../components/layout/main/SettingDetailLayout'
import StakingPoolUserUpdate from '../../../components/main/setting/staking-pools/updates/StakingPoolUserUpdate'
import StakingPoolUser from '../../../components/main/setting/staking-pools/details/StakingPoolUser'

const StakingPools = () => {
	const {
		pools,
		onQuerySuccess,
		editMode,
		enterEditMode,
		exitEditMode,
		onDetailsChange,
		onSubmitChanges,
		loading,
		addNewPool,
		deletePool,
	} = useStakingPools()
	return (
		<>
			<SettingDetailsLayout query={useGetStakingPools} onQuerySuccess={onQuerySuccess}>
				<ListLayout>
					
					<CFlex fw>
						<Flex fw justify='flex-end'>
							<ModeDecoration />
							<DText main fontSize='s'>
								<Text tid='mode' /> :
							</DText>
							<DText main style={{ margin: '0 4px' }} fontSize='s'>
								<Text tid={`${editMode ? 'open' : 'close'}-mode`} />
							</DText>
							{!editMode && (
								<IconWrapper onClick={enterEditMode}>
									<EditIcon size={24} />
								</IconWrapper>
							)}
						</Flex>
						{pools?.map((pool, index) =>
							editMode ? (
								<StakingPoolUpdate
									index={index}
									pool={pool}
									onDetailsChange={onDetailsChange}
									deletePool={deletePool}
								/>
							) : (
								<>
									<StakingPool pool={pool} index={index} />
								</>
							)
						)}

						{editMode && (
							<SettingCard minHeight={'0'} main noTitle>
								<Flex  fw fh>
									<IconWrapper onClick={addNewPool}>
										<DText main >
											<Text tid={'add-pool'} />
										</DText>
									</IconWrapper>
								</Flex>
							</SettingCard>
						)}
					</CFlex>
				</ListLayout>

				<SubmitSettingModal
					editMode={editMode}
					onSubmit={onSubmitChanges}
					onCancel={exitEditMode}
					loading={loading}
				/>
			</SettingDetailsLayout>
		</>
	)
}

export default StakingPools
