import { useEffect, useState } from "react";
import { useSetStakingPools } from "../../../../services/react-query/setting/staking-pools";
import { deepCopy, formatObjectNumbers } from "../../../../utils/common";

const initialStakingPool = {
    poolId: '',
    poolName: '',
    isActive: false,
    minStake: 0,
    maxStake: 0,
    APR: 0,
    startDate: null,
    endDate: null,
};

const useStakingPools = () => {
    const { mutate: updatePool, isLoading: loading, isSuccess: poolUpdated } = useSetStakingPools();

    const [poolCache, setPoolCache] = useState([initialStakingPool]);
    const [pools, setPools] = useState([initialStakingPool]);

    const resetPools = () => {
        setPools(poolCache);
    };

    const [editMode, setEditMode] = useState(false);
    const enterEditMode = () => setEditMode(true);
    const exitEditMode = () => {
        setEditMode(false);
        resetPools();
    };

    const onQuerySuccess = (res) => {
        if (res?.data) {
            setPools(res.data);
            setPoolCache(res.data);
        }
    };

    const onDetailsChange = (value, key, index) => {
        const newPools = deepCopy(pools);
        const pool = newPools[index];
        pool[key] = value;
        setPools(newPools);
    };

    const addNewPool = () => {
        const newPools = deepCopy(pools);
        newPools.push(initialStakingPool);
        setPools(newPools);
    };

    const deletePool = (index) => {
        const newPools = deepCopy(pools);
        newPools.splice(index, 1);
        setPools(newPools);
    };

    const onSubmitChanges = () => {
        if (loading) return;

        // Formatting numbers before submission
        const formattedPools = pools.map(pool => ({
            ...pool
        }));
        updatePool(formattedPools);
    };

    useEffect(() => {
        if (poolUpdated) {
            exitEditMode();
        }
    }, [poolUpdated]);

    return {
        pools,
        onQuerySuccess,
        editMode,
        enterEditMode,
        exitEditMode,
        onDetailsChange,
        onSubmitChanges,
        loading,
        addNewPool,
        deletePool,
    };
};

export default useStakingPools;
