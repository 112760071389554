const BASE_URL = 'https://api.tajbit.com/v1/'
const SOCKET_URL = 'https://api.tajbit.com/'

// const BASE_URL = 'http://localhost:1000/v1/'
// const SOCKET_URL = 'http://localhost:1000/'

export {
    BASE_URL,
    SOCKET_URL
}

