import { useMutation, useQuery } from "react-query";
import { normalFetch } from "../../fetch-api/get";
import { putApi } from "../../fetch-api/put";
import { useQueryContext } from "../../../contexts/query";

const Staking_KEYS = {
    GET_STAKING_POOLS: 'get-staking-pools',
    SET_STAKING_POOLS: 'set-staking-pools',
    GET_ACTIVE_STAKING_POOLS: 'get-active-staking-pools'
}

const useGetStakingPools = () => {
    return useQuery(
        Staking_KEYS.GET_STAKING_POOLS, 
        () => normalFetch('staking/pools'),
        {
            select: res => res?.data?.data,
        }
    );
};

const useGetActiveStakingPools = () => {
    return useQuery(
        Staking_KEYS.GET_ACTIVE_STAKING_POOLS, 
        () => normalFetch('staking/pools/active'),
        {
            select: res => res?.data?.data,
        }
    );
};

const useSetStakingPools = () => {
    const { queryClient, setToast } = useQueryContext();

    return useMutation(
        Staking_KEYS.SET_STAKING_POOLS, 
        (data) => putApi(data, 'staking/stake'),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(Staking_KEYS.GET_STAKING_POOLS);
                setToast({
                    message: 'operation-success', 
                    show: true,
                });
            },
        }
    );
};

export {
    useGetStakingPools,
    useSetStakingPools,
    useGetActiveStakingPools
};
