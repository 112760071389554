import {Absolute, CMargin, Decoration, DText, Flex} from "../../../../../styles/CommonStyles";
import Text from "../../../../../core/utils/Text";
import SettingRow from "../../../../common/common/SettingRow";
import Toggle from "../../../../common/buttons/Toggle";
import {MOBILE_SIZE} from "../../../../../core/constants/common";
import ABActionButton from "../../../setting/general/ABActionButton";
import {useWindowSize} from "../../../../../core/hooks/common/useWindowSize";
import useBlockUserAccess from "../../../../../core/hooks/main/users/useBlockUserAccess";



const BlockAccess = ({ user, refetch }) => {

    const { width } = useWindowSize()
    const {
        onSubmitClicked,
        onInputValueChange,
        accessData,
        loading,
        detailsChanged
    } = useBlockUserAccess(user, refetch)

    return (
        <>
            <CMargin margin={'30px'} />
            <Flex fw justify={'flex-start'}>
                <Decoration />
                <DText main>
                    <Text tid={'block-access-note'} />
                </DText>
            </Flex>
            <CMargin margin={'20px'} />
            <Flex fw wrap>
                <Flex style={{ minWidth: '300px' }}>
                    <SettingRow
                        prefix={'deposit'}
                        suffix={
                            <Toggle
                                active={accessData?.deposit}
                                onChange={() => onInputValueChange(!accessData?.deposit, 'deposit')}
                            />
                        }
                    />
                </Flex>
                <Flex style={{ minWidth: '300px' }}>
                    <SettingRow
                        prefix={'depositCoining'}
                        suffix={
                            <Toggle
                                active={accessData?.depositCoining}
                                onChange={() => onInputValueChange(!accessData?.depositCoining, 'depositCoining')}
                            />
                        }
                    />
                </Flex>
                <Flex style={{ minWidth: '300px' }}>
                    <SettingRow
                        prefix={'depositBanking'}
                        suffix={
                            <Toggle
                                active={accessData?.depositBanking}
                                onChange={() => onInputValueChange(!accessData?.depositBanking, 'depositBanking')}
                            />
                        }
                    />
                </Flex>
                <Flex style={{ minWidth: '300px' }}>
                    <SettingRow
                        prefix={'withdraw'}
                        suffix={
                            <Toggle
                                active={accessData?.withdraw}
                                onChange={() => onInputValueChange(!accessData?.withdraw, 'withdraw')}
                            />
                        }
                    />
                </Flex>
                <Flex style={{ minWidth: '300px' }}>
                    <SettingRow
                        prefix={'withdrawCoining'}
                        suffix={
                            <Toggle
                                active={accessData?.withdrawCoining}
                                onChange={() => onInputValueChange(!accessData?.withdrawCoining, 'withdrawCoining')}
                            />
                        }
                    />
                </Flex>
                <Flex style={{ minWidth: '300px' }}>
                    <SettingRow
                        prefix={'staking'}
                        suffix={
                            <Toggle
                                active={accessData?.staking}
                                onChange={() => onInputValueChange(!accessData?.staking, 'staking')}
                            />
                        }
                    />
                </Flex>
                <Flex style={{ minWidth: '300px' }}>
                    <SettingRow
                        prefix={'withdrawBanking'}
                        suffix={
                            <Toggle
                                active={accessData?.withdrawBanking}
                                onChange={() => onInputValueChange(!accessData?.withdrawBanking, 'withdrawBanking')}
                            />
                        }
                    />
                </Flex>
                <Flex style={{ minWidth: '300px' }}>
                    <SettingRow
                        prefix={'order'}
                        suffix={
                            <Toggle
                                active={accessData?.order}
                                onChange={() => onInputValueChange(!accessData?.order, 'order')}
                            />
                        }
                    />
                </Flex>
                <Flex style={{ minWidth: '300px' }}>
                    <SettingRow
                        prefix={'otc'}
                        suffix={
                            <Toggle
                                active={accessData?.otc}
                                onChange={() => onInputValueChange(!accessData?.otc, 'otc')}
                            />
                        }
                    />
                </Flex>
            </Flex>
            <CMargin margin={'15px'} />
            {width > MOBILE_SIZE ?
                <ABActionButton
                    show={detailsChanged}
                    onClick={onSubmitClicked}
                    loading={loading}
                />
                :
                <Absolute style={{ position: 'fixed' }} left={0} bottom={0} height={'60px'}>
                    <ABActionButton
                        show={detailsChanged}
                        onClick={onSubmitClicked}
                        loading={loading}
                    />
                </Absolute>
            }
        </>
    )
}


export default BlockAccess
