import {
	Absolute,
	CFlex,
	CMargin,
	DeleteIcon,
	DText,
	Flex,
	IconWrapper,
} from '../../../../../styles/CommonStyles'
import Select from '../../../../common/dropdown/Select'
import { useAvailableCoins } from '../../../../../core/services/react-query/setting'
import { useEffect, useState } from 'react'
import Text from '../../../../../core/utils/Text'
import Toggle from '../../../../common/buttons/Toggle'
import RobotOrderBoxUpdate from '../elements/StakingPoolOrderBoxUpdate'
import { LineBreak } from '../../../../../styles/main/MainCommonStyles'
import { CollapseLtr, CollapseRtl, SettingCardWrapper } from '../../../../../styles/main/setting'
import { AnimatePresence, motion } from 'framer-motion'
import { useMainContext } from '../../../../../core/contexts/main'
import { deepCopy } from '../../../../../core/utils/common'
import DatePicker from 'react-multi-date-picker'
import persian from 'react-date-object/calendars/persian'
import persian_fa from 'react-date-object/locales/persian_fa'
import { MOBILE_SIZE } from '../../../../../core/constants/common'
import { useWindowSize } from '../../../../../core/hooks/common/useWindowSize'
import DateInput from '../../../../common/input/DateInput'
import Input from '../../../../common/input/Input'
import StakingPoolOrderBoxUpdate from '../elements/StakingPoolOrderBoxUpdate'
import { FaPlus, FaPrescriptionBottle } from 'react-icons/fa'

const StakingPoolUpdate = (props) => {
	const { lang, theme } = useMainContext()
	const { index, pool, onDetailsChange, deletePool } = props

	const irtCoin = {
		id: 'irt',
		fa: 'تومان',
		name: 'Tooman',
	}

	const { data: availableCoins } = useAvailableCoins()

	const [selectedCoin, setSelectedCoin] = useState(null)
	const [showDetails, setShowDetails] = useState(false)

	useEffect(() => {
		if (availableCoins) {
			const coin = availableCoins.find((item) => item.id === pool.coin)
			if (coin) setSelectedCoin(coin)
		}
	}, [pool, availableCoins])
	// const addPeriod = () => {
	// 	const newPeriod = { APR: 0, Days: 0 }
	// 	onDetailsChange([...(pool.periods || []), newPeriod], 'periods', index)
	// }
	// const deletePeriod = (periodIndex) => {
	// 	const updatedPeriods = pool.periods.filter((_, idx) => idx !== periodIndex)
	// 	onDetailsChange(updatedPeriods, 'periods', index)
	// }
	// const updatePeriod = (value, field, periodIndex) => {
	// 	const updatedPeriods = pool.periods.map((period, idx) =>
	// 		idx === periodIndex ? { ...period, [field]: value } : period
	// 	)
	// 	onDetailsChange(updatedPeriods, 'periods', index)
	// }
	const { width } = useWindowSize()
	// const renderPeriods = () => {
	// 	return (
	// 		<>
	// 			{pool?.periods?.map((period, periodIndex) => (
	// 				<Flex>
	// 					<div>
	// 						<DText main>
	// 							<Text tid={'APR'} />
	// 						</DText>
	// 						<Input
	// 							number
	// 							value={period.APR}
	// 							onChange={(e) => {
	// 								const updatedPeriods = pool.periods.map((p, idx) =>
	// 									idx === periodIndex ? { ...p, APR: Number(e.target.value) } : p
	// 								)
	// 								onDetailsChange(updatedPeriods, 'periods', index)
	// 							}}
	// 						/>
	// 					</div>
	// 					<div>
	// 						<DText main>
	// 							<Text tid={'days'} />
	// 						</DText>
	// 						<Input
	// 							number
	// 							value={period.Days}
	// 							onChange={(e) => {
	// 								const updatedPeriods = pool.periods.map((p, idx) =>
	// 									idx === periodIndex ? { ...p, Days: Number(e.target.value) } : p
	// 								)
	// 								onDetailsChange(updatedPeriods, 'periods', index)
	// 							}}
	// 						/>
	// 					</div>
	// 					<IconWrapper onClick={() => deletePeriod(periodIndex)}>
	// 						<DText main>
	// 							<div className='flex items-center'>
	// 								<Text tid={'delete-period'} /> <FaPrescriptionBottle />
	// 							</div>
	// 						</DText>
	// 					</IconWrapper>
	// 				</Flex>
	// 			))}
	// 			<IconWrapper onClick={addPeriod}>
	// 				<DText main>
	// 					<div className='flex items-center'>
	// 						<Text tid={'add-period'} /> <FaPlus />
	// 					</div>
	// 				</DText>
	// 			</IconWrapper>
	// 		</>
	// 	)
	// }
	//

	const { coin, apr, minStake, maxStake, lockingPeriod, totalStaked, participants, isActive } = pool
	return (
		<SettingCardWrapper minHeight={'0'} main>
			<Flex>
				<CFlex style={{ margin: '8px' }} align={'flex-start'}>
					<DText main>
						{`${index + 1})`} <Text tid={'coin'} />
					</DText>
					<CMargin margin={'4px'} />
					<Select
						options={availableCoins}
						value={selectedCoin}
						onValueChange={(idx) => onDetailsChange(availableCoins[idx].id, 'coin', index)}
						placeHolder={'select-coin'}
						width={'280px'}
						height={'42px'}
						isCoin
					/>
				</CFlex>
			</Flex>
			<Flex fw justify={'center'} wrap align={'flex-end'}>
				<Flex style={{ minWidth: '300px' }} justify={'flex-start'} wrap>
					{/* <CFlex style={{ margin: '8px', borderRadius: '1rem' }} align={'flex-start'}>
						<DText main>
							<Text tid={'start-date'} />
						</DText>
						<DatePicker
							calendar={lang === 'fa' && persian}
							locale={lang === 'fa' && persian_fa}
							value={timeStart}
							onChange={(v) => onDetailsChange(v, 'timeStart', index)}
							render={<DateInput isProfile style={{ borderRadius: '1rem' }} />}
							className={`${theme === 'dark' && 'bg-dark custom-calendar'} teal ${
								width < MOBILE_SIZE && 'rmdp-mobile'
							}`}
							calendarPosition={lang === 'fa' ? 'left-top' : 'right-top'}
						/>
					</CFlex> */}
					{/* <CFlex
						style={{ margin: '8px', borderRadius: '1rem' }}
						className='rounded-xl'
						align={'flex-start'}
					>
						<DText main>
							<Text tid={'end-date'} />
						</DText>
						<DatePicker
							calendar={lang === 'fa' && persian}
							locale={lang === 'fa' && persian_fa}
							value={timeEnd}
							onChange={(v) => onDetailsChange(v, 'timeEnd', index)}
							render={<DateInput isProfile style={{ borderRadius: '1rem' }} />}
							className={`${theme === 'dark' && 'bg-dark custom-calendar'} teal ${
								width < MOBILE_SIZE && 'rmdp-mobile'
							}`}
							calendarPosition={lang === 'fa' ? 'left-top' : 'right-top'}
						/>
					</CFlex> */}
					<CFlex>
						<DText main>
							<Text tid={'MinStackValue'} />
						</DText>
						<CMargin margin={'4px'} />
						<Input
							number
							value={minStake}
							onChange={(e) => onDetailsChange(e.target.value, 'minStake', index)}
							dir={'ltr'}
						/>
					</CFlex>
					<CFlex>
						<DText main>
							<Text tid={'CapacityStackValue'} />
						</DText>
						<CMargin margin={'4px'} />
						<Input
							number
							value={maxStake}
							onChange={(e) => onDetailsChange(e.target.value, 'maxStake', index)}
							// value={value}
							// label={key}
							// onInputChange={(v) => onInputValueChange(key, v)}
						/>
					</CFlex>
					<CFlex>
						<DText main>
							<Text tid={'lockingPeriod'} />
						</DText>
						<CMargin margin={'4px'} />
						<Input
							number
							value={lockingPeriod}
							onChange={(e) => onDetailsChange(e.target.value, 'lockingPeriod', index)}

							// value={value}
							// label={key}
							// onInputChange={(v) => onInputValueChange(key, v)}
						/>
					</CFlex>
					<CFlex>
						<DText main>
							<Text tid={'APR'} />
						</DText>
						<CMargin margin={'4px'} />

						<Input
							number
							value={apr}
							onChange={(e) => {
								onDetailsChange(e.target.value, 'apr', index)
							}}
						/>
					</CFlex>
				</Flex>
			</Flex>
			<Absolute
				left={lang === 'fa' && '10px'}
				right={lang === 'en' && '10px'}
				top={'10px'}
				height={'unset'}
				width={'unset'}
			>
				<Flex>
					<DText main>
						<Text tid={'status'} /> :
					</DText>
					<Toggle
						style={{ margin: '0 8px' }}
						active={isActive}
						onChange={() => onDetailsChange(!isActive, 'isActive', index)}
					/>
					<IconWrapper onClick={() => deletePool(index)}>
						<DeleteIcon size={24} />
					</IconWrapper>
				</Flex>
			</Absolute>

			<LineBreak style={{ margin: '12px auto 0 auto' }} />

			<AnimatePresence initial={false}>
				{showDetails && (
					<motion.div
						style={{ width: '100%', overflow: 'hidden' }}
						variants={collapseVariants}
						initial='collapsed'
						animate='open'
						exit='collapsed'
						transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
					>
						<CFlex fw align={'flex-start'}>
							<CMargin margin={'10px'} />
							<DText primary>
								<Text tid={'order-setting'} />
							</DText>
							<CMargin margin={'4px'} />

							<Flex fw wrap justify={'space-around'}>
								<StakingPoolOrderBoxUpdate {...props} type={'order'} transaction={'buy'} />
								<StakingPoolOrderBoxUpdate {...props} type={'order'} transaction={'sell'} />
							</Flex>

							<CMargin margin={'4px'} />
							<LineBreak />
							<CMargin margin={'6px'} />

							<DText primary>
								<Text tid={'trade-setting'} />
							</DText>
							<CMargin margin={'4px'} />
							<Flex fw wrap justify={'space-around'}>
								<StakingPoolOrderBoxUpdate {...props} type={'trade'} transaction={'buy'} />
								<StakingPoolOrderBoxUpdate {...props} type={'trade'} transaction={'sell'} />
							</Flex>
						</CFlex>
					</motion.div>
				)}
			</AnimatePresence>
		</SettingCardWrapper>
	)
}

const collapseVariants = {
	open: { opacity: 1, height: 'auto' },
	collapsed: { opacity: 0, height: 0 },
}

export default StakingPoolUpdate
